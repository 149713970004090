.site-layout-content {
  min-height: 500px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.contentLayout {
  /* display: flex; */
  width: 100%;
  min-height: 100%;
  position: absolute;
}


@media screen and (min-width: 1201px) {
  #mainContent {
    margin: 20px 150px;
  }
}

@media screen and (max-width: 1200px) {
  #mainContent {
    margin: 20px 100px;
  }
}

@media screen and (max-width: 800px) {
  #mainContent {
    margin: 20px 50px;
  }
}

@media screen and (max-width: 600px) {
  #mainContent {
    margin: 20px 10px;
  }
}

