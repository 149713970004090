@import '~antd/dist/antd.less';
//@import '~antd/dist/antd.dark.less';

.ant-message-notice-content {
  font-size: 16px;
  color: #FFFFFF;
  background-color: #777777;
  opacity: 0.98;
  //border: 1px solid #EEEEEE;
}
@border-radius-base: 5px;